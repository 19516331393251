@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
  .list-dash {
    list-style-type: none;
  }
  .list-dash li {
    margin-left: 20px;
  }
  .list-dash li:before {
    content: '\2014';
    position: absolute;
    margin-left: -20px;
  }
}

@layer base {
  a {
    @apply text-pueblo-400
      underline
      underline-offset-4
      hover:text-pueblo-600
    ;
  }
}

@layer components {
  .btn {
    @apply
      px-2 py-1
      border-2 border-black
      bg-slate-300
      text-black
      hover:bg-amber-400
      hover:text-black
      group-hover:bg-amber-400
      group-hover:text-black
      no-underline
      rounded-md
    ;
  }
}

.rdp-months {
  @apply flex gap-2;
}

.DayPicker-Day--selected {
  @apply bg-red-200;
}
